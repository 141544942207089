import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../components";
import Scrollspy from "react-scrollspy";

const OfferPage = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Offer",
        href: pathname,
        lang: "en",
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/oferta/",
      }}
    >
      <section className="container-fluid page_offer offer_section_1">
        <h1>Offer</h1>
        <div className="row">
          <div className="col-md-3">
            <div className="page_offer-nav">
              <Scrollspy
                items={[
                  "specjalizacje",
                  "strategia",
                  "branding",
                  "ux",
                  "serwisy",
                  "marketing",
                ]}
                currentClassName="current"
                offset={150}
              >
                <li className="current">
                  <a href="#specjalizacje">Specializations</a>
                </li>
                <li>
                  <a href="#strategia">Strategy</a>
                </li>
                <li>
                  <a href="#branding">Branding</a>
                </li>
                <li>
                  <a href="#ux">UX/UI</a>
                </li>
                <li>
                  <a href="#serwisy">Websites</a>
                </li>
                <li>
                  <a href="#marketing">Marketing</a>
                </li>
              </Scrollspy>
            </div>
          </div>
          <div className="col-md-8 offset-md-1">
            <span className="anchor_offer" id="specjalizacje"></span>
            <div className="page_offer-tile">
              <h3>Specializations</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>The power of experience</strong>
                  <p>
                    We work with Clients coming from a wide range of industries
                    but there is a few of them in which we have reached an
                    especially high degree of specialization, because of that we
                    are able to achieve phenomenal, and repetitive results.
                  </p>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li className="link">
                      <a
                        href="https://jamstackagency.co.uk/"
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        Jamstack - fast, efficient and secure internet services
                      </a>
                    </li>
                    <li className="link">
                      <Link to="/en/marketing-for-real-estate-developers/">
                        Marketing for real estate developers
                      </Link>
                    </li>
                    <li>Marketing for hotels</li>
                    <li>Marketing for e-commerce</li>
                    <li>Marketing for gaming companies and products</li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="anchor_offer" id="strategia"></span>
            <div className="page_offer-tile">
              <h3>Strategy</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>Real to implement and effective</strong>
                  <p>
                    A strategy is developed to win - thanks to it we operate on
                    the basis of particular data and not just our imagination
                    and wishful thinking.Iit lets us work according to a plan,
                    and not only ad hoc and spontaneously. Strategy is the most
                    important element of the plan to achieve the brand’s goals.
                    It is the foundation of all communication and advertising
                    activities.
                  </p>
                  <div className="btn_wrapper">
                    <Link to="/en/offer/strategy" className="btn_custom">
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li>Analysis</li>
                    <li>Research</li>
                    <li>Marketing strategy</li>
                    <li>Communication strategy</li>
                    <li>Model Buisness Canvas</li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="anchor_offer" id="branding"></span>
            <div className="page_offer-tile">
              <h3>Branding</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>A new beginning</strong>
                  <p>
                    A brand is not only a logo - it is made of all the areas
                    where a Client interacts with it. We perceive a brand as a
                    whole, without splitting it to parts like print, digital,
                    online, or offline. We create new brands, refresh the old
                    image, change its perception. We do all that in accordance
                    with strategic assumptions
                  </p>
                  <div className="btn_wrapper">
                    <Link to="/en/offer/branding" className="btn_custom">
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li>Logo</li>
                    <li>Key Visual</li>
                    <li>Visual Identification</li>
                    <li>Naming</li>
                    <li>Claim</li>
                    <li>Packaging designs</li>
                    <li>Label designs</li>
                    <li>Web design</li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="anchor_offer" id="ux"></span>
            <div className="page_offer-tile">
              <h3>UX/UI</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>
                    Don’t count on your luck, count on solid UX/UI research
                  </strong>
                  <p>
                    We achieve the effectiveness of our actions by connecting
                    the business goals with our Clients’ real needs. Providing
                    comfortable and intuitive contact with a given brand extends
                    the positive experience of its customers, and increases the
                    chance of reaching the expected sales conversion rate.
                  </p>
                  <div className="btn_wrapper">
                    <Link to="/en/ux-audit" className="btn_custom">
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li className="link">
                      <Link to="/en/ux-audit">UX Audit</Link>
                    </li>
                    <li>Competetive Audit</li>
                    <li>UX tests</li>
                    <li>Traffic analysis</li>
                    <li>Prototyping</li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="anchor_offer" id="serwisy"></span>
            <div className="page_offer-tile">
              <h3>Websites</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>Planned effectiveness and attractive design</strong>
                  <p>
                    Our starting point is always our Client’s business goal and
                    needs. We begin our work on the project when we have
                    understood the idea behind our Client’s business, and after
                    verifying the basic concepts of the project. It is also
                    important to mention that each time we adapt the technology
                    to specific requirements of the project, and not the other
                    way around. There are many ways - it is important to choose
                    the right one, that leads to success, and not the one that
                    everyone else actually follows. There are no excuses for us
                    - it should be attractive and effective at the same time.
                  </p>
                  <div className="btn_wrapper">
                    <Link to="/en/offer/web-development" className="btn_custom">
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li className="link">
                      <a
                        href="https://jamstackagency.co.uk/"
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        Jamstack - fast, efficient and secure internet services
                      </a>
                    </li>
                    <li className="link">
                      <a
                        href="https://webinity.com/"
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        Webinity - Website Administration WordPress &
                        WooCommerce
                      </a>
                    </li>
                    <li>Project workshops</li>
                    <li>Documentations</li>
                    <li>Prototypes</li>
                    <li>Internet websites</li>
                    <li>eStores</li>
                    <li>Web portals</li>
                  </ul>
                </div>
              </div>
            </div>
            <span className="anchor_offer" id="marketing"></span>
            <div className="page_offer-tile">
              <h3>Marketing</h3>
              <div className="row">
                <div className="col-lg-8">
                  <strong>
                    Valuable traffic, creating the expected image.
                  </strong>
                  <p>
                    Marketing’s definition is as wide as the possibilities it
                    has to offer. There are multiple channels, tools, and ways
                    to achieve the goals, and they always require individual
                    analysis and adaptation of the most adequate solutions.Lets
                    set a goal together and we will find the best way to achieve
                    it.
                  </p>
                  <div className="btn_wrapper">
                    <Link to="/en/offer/marketing" className="btn_custom">
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <ul>
                    <li className="link">
                      <Link to="/en/offer/banner-ads/">
                        Advertising banners
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="/en/offer/web-analytics/">
                        Internet analytics
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="/en/offer/social-media/">Social Media</Link>
                    </li>
                    <li className="link">
                      <Link to="/en/offer/linkedin-ads/">Linkedin Ads</Link>
                    </li>
                    <li className="link">
                      <Link to="/en/offer/facebook-ads/">Facebook Ads</Link>
                    </li>
                    <li className="link">
                      <Link to="/en/offer/google-ads/">Google Ads</Link>
                    </li>
                    <li className="link">
                      <Link to="/en/offer/international-campaigns/">
                        International marketing campaigns
                      </Link>
                    </li>
                    <li>Advertising spots</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default OfferPage;
